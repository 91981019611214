import React, { useState, useEffect } from "react";
import { Button } from "antd";
import ReactApexChart from "react-apexcharts";
import { Location } from "../services/timelines";

interface TopTenChartProps {
  data: any;
}

const TopTenChart: React.FC<TopTenChartProps> = ({ data }) => {
  const casesOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: ["#b7eb8f"],
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      formatter: function (val: number) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      style: {
        colors: ["black"],
        fontWeight: 100,
      },
    },
    xaxis: {
      labels: {
        formatter: function (val: number) {
          return val > 1000 ? `${val / 1000}K` : val;
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const deathsOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: ["#cc0000"],
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      formatter: function (val: number) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      style: {
        colors: ["black"],
        fontWeight: 100,
      },
    },
    xaxis: {
      labels: {
        formatter: function (val: number) {
          return val > 1000 ? `${val / 1000}K` : val;
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const [options, setOptions] = useState<any>(casesOptions);
  const [confimed, setConfirmed] = useState<any>([]);
  const [confirmedSelection, setConfirmedSelection] = useState<string>("24H");

  const [deathsOpt, setDeathsOpt] = useState<any>(deathsOptions);
  const [deaths, setDeaths] = useState<any>([]);
  const [deathsSelection, setDeathsSelection] = useState<string>("24H");

  useEffect(() => {
    if (data) {
      let locations = data.locations.sort((a: any, b: any) => {
        if (a.latest.confirmed > b.latest.confirmed) {
          return -1;
        }
        if (b.latest.confirmed > a.latest.confirmed) {
          return 1;
        }
        return 0;
      });
      let _data = {
        ...data,
        locations: locations,
      };
      let confirmed: any[] = [];
      let confirmedCountries: string[] = [];

      _data.locations.forEach((location: Location, index: number) => {
        confirmedCountries.push(location.country);
        confirmed.push(location.latest.confirmed);
      });
      setConfirmed([{ name: "Confirmed", data: confirmed.slice(0, 10) }]);

      let deaths: any[] = [];
      let deathsCountries: string[] = [];

      locations = data.locations.sort((a: any, b: any) => {
        if (a.latest.deaths > b.latest.deaths) {
          return -1;
        }
        if (b.latest.deaths > a.latest.deaths) {
          return 1;
        }
        return 0;
      });
      _data = {
        ...data,
        locations: locations,
      };
      _data.locations.forEach((location: Location, index: number) => {
        deathsCountries.push(location.country);
        deaths.push(location.latest.deaths);
      });
      setDeaths([{ name: "Death", data: deaths.slice(0, 10) }]);

      setOptions({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: confirmedCountries.slice(0, 10),
        },
      });
      setDeathsOpt({
        ...deathsOpt,
        xaxis: {
          ...deathsOpt.xaxis,
          categories: deathsCountries.slice(0, 10),
        },
      });
    }
  }, [data]);

  const updateData = (selection: string) => {
    setConfirmedSelection(selection);

    let locations = data.locations.sort((a: any, b: any) => {
      if (a.latest.confirmed > b.latest.confirmed) {
        return -1;
      }
      if (b.latest.confirmed > a.latest.confirmed) {
        return 1;
      }
      return 0;
    });
    let _data = {
      ...data,
      locations: locations.slice(0, 10),
    };

    let lastKeys: any = [];
    Object.keys(_data.locations[0].timelines.confirmed.timeline).map((key) =>
      lastKeys.push(key)
    );

    switch (selection) {
      case "24H":
        let confirmed: any = [];

        _data.locations.forEach((location: Location) => {
          confirmed.push(location.latest.confirmed);
        });
        setConfirmed([{ name: "Confirmed", data: confirmed }]);
        break;
      case "3D":
        let confirmed3D: number[] = [];

        _data.locations.forEach((location: Location) => {
          //Confirmed - Last days
          confirmed3D.push(
            location.timelines.confirmed.timeline[lastKeys[lastKeys.length - 3]]
          );
        });
        setConfirmed([{ name: "Confirmed", data: confirmed3D }]);
        break;
      case "7D":
        let confirmed7D: number[] = [];

        _data.locations.forEach((location: Location) => {
          confirmed7D.push(
            location.timelines.confirmed.timeline[lastKeys[lastKeys.length - 7]]
          );
        });
        setConfirmed([{ name: "Confirmed", data: confirmed7D }]);
        break;
      case "14D":
        let confirmed14D: number[] = [];

        _data.locations.forEach((location: Location) => {
          confirmed14D.push(
            location.timelines.confirmed.timeline[
              lastKeys[lastKeys.length - 14]
            ]
          );
        });
        setConfirmed([{ name: "Confirmed", data: confirmed14D }]);
        break;
      case "30D":
        let confirmed30D: number[] = [];

        _data.locations.forEach((location: Location) => {
          confirmed30D.push(
            location.timelines.confirmed.timeline[
              lastKeys[lastKeys.length - 30]
            ]
          );
        });
        setConfirmed([{ name: "Confirmed", data: confirmed30D }]);
        break;
      default:
    }
  };

  const updateDeathData = (selection: string) => {
    setDeathsSelection(selection);

    let locations = data.locations.sort((a: any, b: any) => {
      if (a.latest.deaths > b.latest.deaths) {
        return -1;
      }
      if (b.latest.deaths > a.latest.deaths) {
        return 1;
      }
      return 0;
    });
    let _data = {
      ...data,
      locations: locations.slice(0, 10),
    };

    let lastKeys: any = [];
    Object.keys(_data.locations[0].timelines.deaths.timeline).map((key) =>
      lastKeys.push(key)
    );

    switch (selection) {
      case "24H":
        let deaths: any = [];

        _data.locations.forEach((location: Location) => {
          deaths.push(location.latest.deaths);
        });
        setDeaths([{ name: "Deaths", data: deaths }]);
        break;
      case "3D":
        let deaths3D: number[] = [];

        _data.locations.forEach((location: Location) => {
          //deaths - Last days
          deaths3D.push(
            location.timelines.deaths.timeline[lastKeys[lastKeys.length - 3]]
          );
        });
        setDeaths([{ name: "Deaths", data: deaths3D }]);
        break;
      case "7D":
        let deaths7D: number[] = [];

        _data.locations.forEach((location: Location) => {
          deaths7D.push(
            location.timelines.deaths.timeline[lastKeys[lastKeys.length - 7]]
          );
        });
        setDeaths([{ name: "Deaths", data: deaths7D }]);
        break;
      case "14D":
        let deaths14D: number[] = [];

        _data.locations.forEach((location: Location) => {
          deaths14D.push(
            location.timelines.deaths.timeline[lastKeys[lastKeys.length - 14]]
          );
        });
        setDeaths([{ name: "Deaths", data: deaths14D }]);
        break;
      case "30D":
        let deaths30D: number[] = [];

        _data.locations.forEach((location: Location) => {
          deaths30D.push(
            location.timelines.deaths.timeline[lastKeys[lastKeys.length - 30]]
          );
        });
        setDeaths([{ name: "Confirmed", data: deaths30D }]);
        break;
      default:
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="mb-3 lead bold text-center">
            Top 10 Most Impacted Countries - Confirmed Cases Globally
          </div>
          <div className="toolbar text-center mb-3">
            <Button
              onClick={() => updateData("24H")}
              className={confirmedSelection === "24H" ? "active mr-1" : "mr-1"}
            >
              24 Hours Ago
            </Button>
            <Button
              onClick={() => updateData("3D")}
              className={confirmedSelection === "3D" ? "active mr-1" : "mr-1"}
            >
              3 Days Ago
            </Button>
            <Button
              onClick={() => updateData("7D")}
              className={confirmedSelection === "7D" ? "active mr-1" : "mr-1"}
            >
              7 Days Ago
            </Button>
            <Button
              onClick={() => updateData("14D")}
              className={confirmedSelection === "14D" ? "active mr-1" : "mr-1"}
            >
              14 Days Ago
            </Button>
            <Button
              onClick={() => updateData("30D")}
              className={confirmedSelection === "30D" ? "active mr-1" : "mr-1"}
            >
              30 Days Ago
            </Button>
          </div>
          <ReactApexChart
            options={options}
            series={confimed}
            type="bar"
            height={500}
          />
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="mb-3 lead bold text-center">
            Top 10 Most Impacted Countries - Deaths Globally
          </div>
          <div className="toolbar text-center mb-3">
            <Button
              onClick={() => updateDeathData("24H")}
              className={deathsSelection === "24H" ? "active mr-1" : "mr-1"}
            >
              24 Hours Ago
            </Button>
            <Button
              onClick={() => updateDeathData("3D")}
              className={deathsSelection === "3D" ? "active mr-1" : "mr-1"}
            >
              3 Days Ago
            </Button>
            <Button
              onClick={() => updateDeathData("7D")}
              className={deathsSelection === "7D" ? "active mr-1" : "mr-1"}
            >
              7 Days Ago
            </Button>
            <Button
              onClick={() => updateDeathData("14D")}
              className={deathsSelection === "14D" ? "active mr-1" : "mr-1"}
            >
              14 Days Ago
            </Button>
            <Button
              onClick={() => updateDeathData("30D")}
              className={deathsSelection === "30D" ? "active mr-1" : "mr-1"}
            >
              30 Days Ago
            </Button>
          </div>
          <ReactApexChart
            options={deathsOpt}
            series={deaths}
            type="bar"
            height={500}
          />
        </div>
      </div>
    </>
  );
};

export default TopTenChart;
