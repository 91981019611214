export interface Latest {
  confirmed: number;
  deaths: number;
  recovered: number;
}

export interface Coordinate {
  latitude: string;
  longitude: string;
}

export interface TimelineObject {
  latest: number;
  timeline: any;
}

export interface Timeline {
  confirmed: TimelineObject;
  deaths: TimelineObject;
  recovered: TimelineObject;
}

export interface Location {
  coordinates: Coordinate;
  country: string;
  country_code: string;
  id: number;
  last_updated: string;
  province: string;
  latest: Latest;
  timelines: Timeline;
}

export interface TIMELINE_RESPONSE {
  latest: Latest;
  locations: Location[];
}

export function getTimeline(): Promise<TIMELINE_RESPONSE> {
  return fetch(
    "https://coronavirus-tracker-api.herokuapp.com/v2/locations?timelines=1"
  )
    .then(response => response.json())
    .then(data => {
      const requiredData = [];
      const map = new Map();
      for (const item of data.locations) {
        if (!map.has(item.country)) {
          map.set(item.country, item); // set any value to Map
          requiredData.push({
            ...item
          });
        } else {
          let current = map.get(item.country);
          let timelineConfirmed: any = {};
          let timelineDeaths: any = {};
          let timelineRecovered: any = {};
          Object.keys(current.timelines.confirmed.timeline).forEach(key => {
            timelineConfirmed[key] =
              parseInt(current.timelines.confirmed.timeline[key]) +
              parseInt(item.timelines.confirmed.timeline[key]);
            timelineDeaths[key] =
              parseInt(current.timelines.deaths.timeline[key]) +
              parseInt(item.timelines.deaths.timeline[key]);
            timelineRecovered[key] =
              parseInt(current.timelines.recovered.timeline[key]) +
              parseInt(item.timelines.recovered.timeline[key]);
          });

          let newValue = {
            ...item,
            latest: {
              confirmed: current.latest.confirmed + item.latest.confirmed,
              deaths: current.latest.deaths + item.latest.deaths,
              recovered: current.latest.recovered + item.latest.recovered
            },
            timelines: {
              ...current.timelines,
              confirmed: {
                ...current.timelines.confirmed,
                timeline: timelineConfirmed
              },
              deaths: {
                ...current.timelines.deaths,
                timeline: timelineDeaths
              },
              recovered: {
                ...current.timelines.recovered,
                timeline: timelineRecovered
              }
            }
          };
          map.set(item.country, newValue);
          let index: number = requiredData.findIndex(
            rec => rec.country === item.country
          );
          requiredData[index] = newValue;
        }
      }
      return { ...data, locations: requiredData };
    });
}

export function getLocationData(
  countryCode: string
): Promise<TIMELINE_RESPONSE> {
  return fetch(
    `https://coronavirus-tracker-api.herokuapp.com/v2/locations?timelines=1`
  )
    .then(response => response.json())
    .then(data => {
      console.log("Before Filter", data);
      return {
        ...data,
        locations: data.locations.filter(
          (rec: Location) => rec.country_code === countryCode
        )
      };
    });
}
