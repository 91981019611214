import { CoffeeOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { BackTop, Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import Product from "../components/Product";
import Navbar from "../components/Navbar";

export interface PrimaryLayoutProps {
  children: React.ReactNode;
}

const PrimaryLayout: React.FC<PrimaryLayoutProps> = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <div className="custom-navbar">
        <img src="/assets/images/logo-white.png" />
        <span className="align-middle">OUTBREAK TRACKER</span>
      </div>
      <div className="container-fluid">
        <Navbar />
        <BackTop />
        {children}
      </div>
      {/* <div className="custom-footer">
        <Button
          style={{
            backgroundColor: "#1a8cff",
            color: "white",
            marginRight: "5px"
          }}
          icon={<ShoppingCartOutlined style={{ fontSize: "24px" }} />}
          size="large"
          onClick={toggleModal}
        >
          Shop for Essential Items
        </Button>
        <br />
        <Button
          style={{ backgroundColor: "#d9d9d9", marginTop: "5px" }}
          icon={<CoffeeOutlined style={{ fontSize: "24px" }} />}
          size="large"
        >
          Treat Us for a Coffee
        </Button>
        <Modal
          title=""
          visible={showModal}
          onOk={toggleModal}
          okText="Close"
          closable={false}
          centered={true}
          cancelButtonProps={{ style: { display: "none" } }}
          style={{ width: "400px" }}
        >
          <div className="flex" style={{ minHeight: "300px" }}>
            <Row>
              <Col span={12}>
                <Product title="Hand Sanitizer" price={4.75} />
              </Col>
              <Col span={12}>
                <Product title="Disinfectant Wipes" price={3.95} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col span={12}>
                <Product title="Reuseable Face Mask" price={8.95} />
              </Col>
            </Row>
          </div>
        </Modal>
      </div> */}
    </>
  );
};

export default PrimaryLayout;
