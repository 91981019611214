import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./views/Home";
import Charts from "./views/Charts";
import Loader from "./components/Loader";

const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/charts" component={Charts} />
      {/* <Route path="/loader" component={Loader} /> */}
      <Route path="/" component={Home} />
      <Redirect to="/" />
    </Switch>
  );
};

export default App;
