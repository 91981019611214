import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 28, color: "#800000" }} spin />;

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <div className="content">
        {/* <div className="header mb-5">
          <img src="/assets/favicon/ms-icon-310x310.png" />
          <span className="display-4">OUTBREAK TRACKER</span>
        </div> */}
        <div className="header-img mb-5">
          <img src="/assets/images/header.png"/>
        </div>
        <Spin indicator={antIcon} />
        <p>Retrieving data...</p>
      </div>
    </div>
  );
};

export default Loader;
