import React from "react";
import { Affix, Button } from "antd";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  console.log("History", history.location.pathname);

  return (
    <div className="row mt-4 mb-2">
      <div className="col-md-12 col-lg-6">
        <div className="lead nav-title">Coronavirus (COVID-19) Statistics</div>
      </div>
      <div className="col-md-12 col-lg-6 nav-buttons">
        <Affix offsetTop={20}>
          <>
            <Button
              style={
                history.location.pathname === "/"
                  ? { backgroundColor: "#ffeb99" }
                  : {}
              }
              onClick={e => history.push("/")}
            >
              Data View
            </Button>
            <Button
              style={
                history.location.pathname === "/charts"
                  ? { backgroundColor: "#ffeb99" }
                  : {}
              }
              onClick={e => history.push("/charts")}
            >
              Chart View
            </Button>
          </>
        </Affix>
      </div>
    </div>
  );
};

export default Navbar;
