import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Location } from "../services/timelines";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface CountryChartProps {
  data: any;
}

const initOptions = {
  chart: {
    type: "area",
  },
  colors: ["#b7eb8f", "#cc0000"],
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
    min: new Date().getTime() - 2629746000,
    tickAmount: 6,
  },
  yaxis: {
    labels: {
      formatter: function (val: number) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
    y: {
      formatter: function (val: number) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      stops: [0, 100],
    },
  },
  legend: {
    itemMargin: {
      horizontal: 10,
      vertical: 20,
    },
  }
};

const CountryChart: React.FC<CountryChartProps> = ({ data }) => {
  const [series, setSeries] = useState<any>([]);
  const [options, setOptions] = useState<any>(initOptions);
  const [selection, setSelection] = useState<string>("one_month");

  useEffect(() => {
    if (data) {
      let confirmed: any[] = [];
      let deaths: any[] = [];
      data.locations.forEach((location: Location, index: number) => {
        if (index === 0) {
          Object.keys(location.timelines.confirmed.timeline).forEach(
            async (key) => {
              confirmed.push([key, location.timelines.confirmed.timeline[key]]);
            }
          );
          Object.keys(location.timelines.deaths.timeline).forEach((key) => {
            deaths.push([key, location.timelines.deaths.timeline[key]]);
          });
        } else {
          Object.keys(location.timelines.confirmed.timeline).forEach(
            (key, idx) =>
              (confirmed[idx][1] =
                confirmed[idx][1] + location.timelines.confirmed.timeline[key])
          );

          Object.keys(location.timelines.deaths.timeline).forEach(
            (key, idx) =>
              (deaths[idx][1] =
                deaths[idx][1] + location.timelines.deaths.timeline[key])
          );
        }
      });
      setSeries([
        { name: "Confirmed", data: confirmed },
        { name: "Deaths", data: deaths },
      ]);
    }
  }, [data]);

  const updateData = (selection: string) => {
    setSelection(selection);

    switch (selection) {
      case "one_month":
        setOptions({
          ...options,
          xaxis: {
            min: new Date().getTime() - 2629746000,
            max: new Date().getTime(),
          },
        });
        break;
      case "two_months":
        setOptions({
          ...options,
          xaxis: {
            min: new Date().getTime() - 2629746000 * 2,
            max: new Date().getTime(),
          },
        });
        break;
      case "three_months":
        setOptions({
          ...options,
          xaxis: {
            min: new Date().getTime() - 2629746000 * 3,
            max: new Date().getTime(),
          },
        });
        break;
      case "six_months":
        setOptions({
          ...options,
          xaxis: {
            min: new Date().getTime() - 2629746000 * 6,
            max: new Date().getTime(),
          },
        });
        break;
      case "one_year":
        setOptions({
          ...options,
          xaxis: {
            min: new Date().getTime() - 2629746000 * 12,
            max: new Date().getTime(),
          },
        });
        break;
      case "ytd":
        setOptions({
          ...options,
          xaxis: {
            min: new Date(`01 Jan ${new Date().getFullYear()}`).getTime(),
            max: new Date().getTime(),
          },
        });
        break;
      case "all":
        setOptions({
          ...options,
          xaxis: {
            min: undefined,
            max: undefined,
          },
        });
        break;
      default:
    }
  };

  return (
    <>
      <div className="text-center lead bold mb-4">
        Total Confirmed Cases and Deaths Worldwide
      </div>
      {series.length > 0 ? (
        <div id="chart">
          <div className="toolbar text-center mb-3">
            <Button
              onClick={() => updateData("one_month")}
              className={selection === "one_month" ? "active mr-1" : "mr-1"}
            >
              1M
            </Button>
            <Button
              onClick={() => updateData("two_months")}
              className={selection === "two_months" ? "active mr-1" : "mr-1"}
            >
              2M
            </Button>
            <Button
              onClick={() => updateData("three_months")}
              className={selection === "three_months" ? "active mr-1" : "mr-1"}
            >
              3M
            </Button>
            <Button
              onClick={() => updateData("six_months")}
              className={selection === "six_months" ? "active mr-1" : "mr-1"}
            >
              6M
            </Button>
            {/* <Button
              onClick={() => updateData("one_year")}
              className={selection === "one_year" ? "active mr-1" : "mr-1"}
            >
              1Y
            </Button> */}
            <Button
              onClick={() => updateData("ytd")}
              className={selection === "ytd" ? "active mr-1" : "mr-1"}
            >
              YTD
            </Button>
            {/* <Button
              onClick={() => updateData("all")}
              className={selection === "all" ? "active mr-1" : "mr-1"}
            >
              ALL
            </Button> */}
          </div>

          <div id="chart-timeline">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        </div>
      ) : (
        <Spin indicator={antIcon} className="d-block text-center pt-5" />
      )}
    </>
  );
};

export default CountryChart;
