import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { Card, Input, Modal, Spin, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getLocationData, Location } from "../services/timelines";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface GlobalTableProps {
  timeline: any;
}

const GlobalTable: React.FC<GlobalTableProps> = ({ timeline }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const [modalTitle, setModalTitle] = useState<string>("");
  const toggleModal = () => setShowModal(!showModal);
  const [displayData, setDisplayData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>();

  useEffect(() => {
    if (timeline.length > 0) {
      let _displayData = timeline.map((row: Location) => {
        let chunk = Object.keys(row.timelines.confirmed.timeline);
        let last: any = chunk.pop();
        let secondLast: any = chunk.pop();

        let total = Object.keys(row.timelines.confirmed.timeline).length;
        let confirmed = 0;
        let confirmedPercent = 0;
        if (total > 0) {
          confirmed =
            parseInt(row.timelines.confirmed.timeline[last]) -
            parseInt(row.timelines.confirmed.timeline[secondLast]);
          confirmedPercent =
            (confirmed / parseInt(row.timelines.confirmed.timeline[last])) *
            100;
        }

        total = Object.keys(row.timelines.deaths.timeline).length;
        let deaths =
          total > 0
            ? parseInt(row.timelines.deaths.timeline[last]) -
              parseInt(row.timelines.deaths.timeline[secondLast])
            : 0;
        let deathPercentage =
          (deaths / parseInt(row.timelines.confirmed.timeline[last])) * 100;

        total = Object.keys(row.timelines.recovered.timeline).length;
        let recovered =
          total > 0
            ? parseInt(row.timelines.recovered.timeline[last]) -
              parseInt(row.timelines.recovered.timeline[secondLast])
            : 0;

        let lastKeys: any = [];
        Object.keys(row.timelines.confirmed.timeline).map(key =>
          lastKeys.push(key)
        );
        //Confirmed - Last days
        let confirmed3D =
          row.timelines.confirmed.timeline[lastKeys[lastKeys.length - 3]];
        let confirmed7D =
          row.timelines.confirmed.timeline[lastKeys[lastKeys.length - 7]];
        let confirmed14D =
          row.timelines.confirmed.timeline[lastKeys[lastKeys.length - 14]];

        //Deaths - Last days
        let deaths3D =
          row.timelines.deaths.timeline[lastKeys[lastKeys.length - 3]];
        let deaths7D =
          row.timelines.deaths.timeline[lastKeys[lastKeys.length - 7]];
        let deaths14D =
          row.timelines.deaths.timeline[lastKeys[lastKeys.length - 14]];

        return {
          ...row,
          today: {
            confirmed,
            confirmedPercent,
            deaths,
            deathPercentage,
            recovered
          },
          lastDays: {
            confirmed: {
              three: confirmed3D,
              seven: confirmed7D,
              fourteen: confirmed14D
            },
            deaths: {
              three: deaths3D,
              seven: deaths7D,
              fourteen: deaths14D
            }
          }
        };
      });
      console.log("Display", _displayData);
      setDisplayData(_displayData);
      setInitialData(_displayData);
    }
  }, [timeline]);

  function numberWithCommas(x: Number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = [
    // {
    //   title: "No.",
    //   fixed: "left",
    //   render: (text: string, record: any, index: number) => (
    //     <span className="pl-2">{index + 1}</span>
    //   )
    // },
    {
      title: <div>Country</div>,
      dataIndex: "country",
      key: "country",
      fixed: "left",
      render: (country: string, record: any) => (
        <Typography.Text
          style={{ width: 80, verticalAlign: "middle", marginLeft: "5px" }}
        >
          <span onClick={e => onRowClick(record)}>{country}</span>
        </Typography.Text>
      ),
      sorter: (a: any, b: any) => {
        if (a.country < b.country) {
          return -1;
        }
        if (b.country < a.country) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: <div className="text-center">Total Confirmed</div>,
      key: "cases",
      width: 100,
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="green">{numberWithCommas(row?.latest?.confirmed)}</Tag>
        </span>
      ),
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.latest.confirmed - b.latest.confirmed,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Changes <br /> <small>(Last 24 Hours)</small>
        </Typography.Text>
      ),
      width: 80,
      render: (row: any) => (
        <span className="d-block pl-2 pr-3">
          {row.today.confirmed > 0 ? (
            <>
              <CaretUpOutlined
                style={{
                  color: "#33cc33",
                  verticalAlign: "middle"
                }}
                className="d-inline-block mr-2"
              />
              <div
                className="d-inline-block"
                style={{
                  color: "#52C41A",
                  verticalAlign: "middle"
                }}
              >
                {numberWithCommas(row?.today?.confirmed)}
                {/* {` (${row?.today?.confirmedPercent.toFixed(2)}%)`} */}
              </div>
            </>
          ) : (
            <div className="text-center">No Change</div>
          )}
        </span>
      ),
      sorter: (a: any, b: any) => a.today.confirmed - b.today.confirmed,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Confirmed <br /> <small>(3 days ago)</small>
        </Typography.Text>
      ),
      width: 100,
      key: "confirmedLast3Days",
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="green">
            {numberWithCommas(row.lastDays.confirmed.three)}
          </Tag>
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.lastDays.confirmed.three - b.lastDays.confirmed.three,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Confirmed <br /> <small>(7 days ago)</small>
        </Typography.Text>
      ),
      width: 100,
      key: "confirmedLast7Days",
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="green">
            {numberWithCommas(row.lastDays.confirmed.seven)}
          </Tag>
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.lastDays.confirmed.seven - b.lastDays.confirmed.seven,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Confirmed <br /> <small>(14 days ago)</small>
        </Typography.Text>
      ),
      width: 100,
      key: "confirmedLast14Days",
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="green">
            {numberWithCommas(row.lastDays.confirmed.fourteen)}
          </Tag>
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.lastDays.confirmed.fourteen - b.lastDays.confirmed.fourteen,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: <div className="text-center">Total Deaths</div>,
      key: "deaths",
      width: 100,
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="red">{numberWithCommas(row?.latest?.deaths)}</Tag>
        </span>
      ),
      sorter: (a: any, b: any) => a.latest.deaths - b.latest.deaths,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Changes <br /> <small>(Last 24 Hours)</small>
        </Typography.Text>
      ),
      width: 80,
      render: (row: any) => (
        <span className="d-block pl-2 pr-3">
          {row.today.deaths > 0 ? (
            <>
              <CaretUpOutlined
                style={{
                  color: "red",
                  verticalAlign: "middle"
                }}
                className="d-inline-block mr-2"
              />
              <div className="d-inline-block text-danger">
                {numberWithCommas(row?.today?.deaths)}
                {/* {` (${row?.today?.deathPercentage.toFixed(2)}%)`} */}
              </div>
            </>
          ) : (
            <div className="text-center">No Change</div>
          )}
        </span>
      ),
      sorter: (a: any, b: any) => a.today.deaths - b.today.deaths,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Deaths <br /> <small>(3 days ago)</small>
        </Typography.Text>
      ),
      width: 100,
      key: "deathsLast3Days",
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="red">{numberWithCommas(row.lastDays.deaths.three)}</Tag>
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.lastDays.deaths.three - b.lastDays.deaths.three,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Deaths <br /> <small>(7 days ago)</small>
        </Typography.Text>
      ),
      width: 100,
      key: "deathsLast7Days",
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="red">{numberWithCommas(row.lastDays.deaths.seven)}</Tag>
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.lastDays.deaths.seven - b.lastDays.deaths.seven,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Deaths <br /> <small>(14 days ago)</small>
        </Typography.Text>
      ),
      width: 100,
      key: "deathsLast14Days",
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="red">
            {numberWithCommas(row.lastDays.deaths.fourteen)}
          </Tag>
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.lastDays.deaths.fourteen - b.lastDays.deaths.fourteen,
      sortDirections: ["descend", "ascend"]
    }
    // {
    //   title: <div className="text-center">Recovered</div>,
    //   key: "recovered",
    //   render: (row: any) => (
    //     <span className="d-block text-center">
    //       <Tag color="gold">{numberWithCommas(row?.latest?.recovered)}</Tag>
    //     </span>
    //   )
    // },
    // {
    //   title: (
    //     <div className="text-center">
    //       <span>Changes</span>
    //       <br />
    //       <small>(Last 24 Hours)</small>
    //     </div>
    //   ),
    //   render: (row: any) => (
    //     <span className="d-block text-center">
    //       {numberWithCommas(row?.today?.recovered) || 0}
    //     </span>
    //   )
    // }
  ];

  const modalColumns = [
    // {
    //   title: "No.",
    //   fixed: "left",
    //   render: (text: string, record: any, index: number) => (
    //     <span className="pl-2">{index + 1}</span>
    //   )
    // },
    {
      title: <div style={{ verticalAlign: "middle" }}>State/Province</div>,
      dataIndex: "province",
      key: "province",
      fixed: "left",
      render: (province: string) => <span className="pl-3">{province}</span>,
      sorter: (a: any, b: any) => {
        if (a.province < b.province) {
          return -1;
        }
        if (b.province < a.province) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: <div className="text-center">Confirmed</div>,
      key: "cases",
      width: 100,
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="green">{numberWithCommas(row?.latest?.confirmed)}</Tag>
        </span>
      ),
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.latest.confirmed - b.latest.confirmed,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Changes <br /> <small>(Last 24 Hours)</small>
        </Typography.Text>
      ),
      width: 80,
      render: (row: any) => (
        <span className="d-block">
          {row.today.confirmed > 0 ? (
            <>
              <CaretUpOutlined
                style={{
                  color: "#33cc33",
                  verticalAlign: "middle"
                }}
                className="d-inline-block pl-3 mr-2"
              />
              <div
                className="d-inline-block"
                style={{
                  color: "#52C41A",
                  verticalAlign: "middle"
                }}
              >
                {numberWithCommas(row?.today?.confirmed)}
                {/* {` (${row?.today?.confirmedPercent.toFixed(2)}%)`} */}
              </div>
            </>
          ) : (
            <div className="text-center">No Change</div>
          )}
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.today.confirmedPercent - b.today.confirmedPercent,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: <div className="text-center">Deaths</div>,
      key: "deaths",
      width: 100,
      render: (row: any) => (
        <span className="d-block text-center">
          <Tag color="red">{numberWithCommas(row?.latest?.deaths)}</Tag>
        </span>
      ),
      sorter: (a: any, b: any) => a.latest.deaths - b.latest.deaths,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: (
        <Typography.Text
          ellipsis={true}
          style={{ width: 80 }}
          className="text-center"
        >
          Changes <br /> <small>(Last 24 Hours)</small>
        </Typography.Text>
      ),
      width: 80,
      render: (row: any) => (
        <span className="d-block">
          {row.today.deaths > 0 ? (
            <>
              <CaretUpOutlined
                style={{
                  color: "red",
                  verticalAlign: "middle"
                }}
                className="d-inline-block pl-3 mr-2"
              />
              <div
                className="d-inline-block text-danger"
                style={{
                  verticalAlign: "middle"
                }}
              >
                {numberWithCommas(row?.today?.deaths)}
                {/* {` (${row?.today?.deathPercentage.toFixed(2)}%)`} */}
              </div>
            </>
          ) : (
            <div className="text-center">No Change</div>
          )}
        </span>
      ),
      sorter: (a: any, b: any) =>
        a.today.deathPercentage - b.today.deathPercentage,
      sortDirections: ["descend", "ascend"]
    }
  ];

  async function onRowClick(record: Location) {
    console.log("Row", record);
    setModalTitle(record.country);
    let res = await getLocationData(record.country_code);
    console.log("Response", res.locations);
    if (res.locations.length > 1) {
      toggleModal();
      let _displayData = res.locations.map((row: Location) => {
        let chunk = Object.keys(row.timelines.confirmed.timeline);
        let last: any = chunk.pop();
        let secondLast: any = chunk.pop();

        let total = Object.keys(row.timelines.confirmed.timeline).length;
        let confirmed = 0;
        let confirmedPercent = 0;
        if (total > 0) {
          confirmed =
            parseInt(row.timelines.confirmed.timeline[last]) -
            parseInt(row.timelines.confirmed.timeline[secondLast]);
          confirmedPercent =
            (confirmed / parseInt(row.timelines.confirmed.timeline[last])) *
            100;
        }

        total = Object.keys(row.timelines.deaths.timeline).length;
        let deaths =
          total > 0
            ? parseInt(row.timelines.deaths.timeline[last]) -
              parseInt(row.timelines.deaths.timeline[secondLast])
            : 0;
        let deathPercentage =
          (deaths / parseInt(row.timelines.confirmed.timeline[last])) * 100;

        total = Object.keys(row.timelines.recovered.timeline).length;
        let recovered =
          total > 0
            ? parseInt(row.timelines.recovered.timeline[last]) -
              parseInt(row.timelines.recovered.timeline[secondLast])
            : 0;

        return {
          ...row,
          today: {
            confirmed,
            confirmedPercent,
            deaths,
            deathPercentage,
            recovered
          }
        };
      });
      console.log("Display", _displayData);
      setModalData(_displayData);
    }
  }

  function handleSearch(value: string) {
    if (value === "") {
      setDisplayData(initialData);
    } else {
      let searched = initialData.filter((rec: Location) =>
        rec.country.toLowerCase().includes(value.toLowerCase())
      );
      if (searched.length > 0) setDisplayData(searched);
    }
  }

  return displayData.length > 0 ? (
    <>
      <div className="global-table">
        <Card className="mb-2" style={{ backgroundColor: "#8c8c8c" }}>
          <Input
            type="text"
            className="form-control d-inline-block mr-2"
            placeholder="Search for a country by name"
            size="large"
            onChange={e => handleSearch(e.target.value)}
          />
        </Card>
        <Table
          columns={columns as any}
          dataSource={displayData}
          size="small"
          pagination={false}
          tableLayout={"auto"}
          scroll={{ x: true }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: event => onRowClick(record), // click row
          //     onDoubleClick: event => {}, // double click row
          //     onContextMenu: event => {}, // right button click row
          //     onMouseEnter: event => {}, // mouse enter row
          //     onMouseLeave: event => {} // mouse leave row
          //   };
          // }}
          // style={{ height: "400px", overflowY: "auto" }}
        />
      </div>
      <Modal
        title={`${modalTitle} (${modalData.length})`}
        visible={showModal}
        onOk={toggleModal}
        okText="Close"
        closable={false}
        centered={true}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ width: "600px" }}
      >
        {modalData.length > 0 ? (
          <div
            style={{
              overflowX: "auto",
              margin: "0px -10px",
              padding: "0",
              width: "105%"
            }}
          >
            <Table
              columns={modalColumns as any}
              dataSource={modalData}
              size="small"
              pagination={false}
              style={{ height: "350px", overflowY: "auto" }}
            />
          </div>
        ) : (
          <Spin indicator={antIcon} className="d-block text-center pt-3 pb-3" />
        )}
      </Modal>
    </>
  ) : (
    <Spin indicator={antIcon} className="d-block text-center pt-5" />
  );
};

export default GlobalTable;
