import ApexCharts from "apexcharts";
import React, { useEffect, useState } from "react";
import { getTimeline } from "../services/timelines";
import CountryChart from "../components/CountryChart";
import PrimaryLayout from "../layout/Primary";
import CountryBarChart from "../components/CountryBarChart";
import TopTenChart from "../components/TopTenChart";
import Loader from "../components/Loader";

const Charts: React.FC = () => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    async function loadData() {
      const data = await getTimeline();
      console.log("Data", data);
      setData(data);

      //let confirmed: any = [];
      //let deaths: any = [];
      //   data.locations.forEach(location => {
      //     confirmed.push({
      //       x: location.last_updated.substr(0, 9),
      //       y: location.latest.confirmed
      //     });
      //     deaths.push({
      //       x: location.last_updated.substr(0, 9),
      //       y: location.latest.confirmed
      //     });
      //   });
      // let confirmed: any[] = [];
      // let deaths: any[] = [];
      // data.locations.forEach((location, index) => {
      //   if (index === 0) {
      //     Object.keys(location.timelines.confirmed.timeline).forEach(key => {
      //       confirmed.push({
      //         x: key.substr(0, 10),
      //         y: location.timelines.confirmed.timeline[key]
      //       });
      //     });
      //     Object.keys(location.timelines.deaths.timeline).forEach(key => {
      //       deaths.push({
      //         x: key.substr(0, 10),
      //         y: location.timelines.deaths.timeline[key]
      //       });
      //     });
      //   } else {
      //     Object.keys(location.timelines.confirmed.timeline).forEach(
      //       (key, idx) => {
      //         confirmed[idx] = {
      //           ...confirmed[idx],
      //           y: confirmed[idx].y + location.timelines.confirmed.timeline[key]
      //         };
      //       }
      //     );

      //     Object.keys(location.timelines.deaths.timeline).forEach(
      //       (key, idx) => {
      //         deaths[idx] = {
      //           ...deaths[idx],
      //           y: deaths[idx].y + location.timelines.deaths.timeline[key]
      //         };
      //       }
      //     );
      //   }
      // });

      // //console.log("Confirmed", confirmed);

      // const barChartOptions = {
      //   chart: {
      //     type: "bar"
      //   },
      //   series: [
      //     {
      //       data: [44, 55, 41, 64, 22, 43, 21]
      //     },
      //     {
      //       data: [53, 32, 33, 52, 13, 44, 32]
      //     }
      //   ],
      //   options: {
      //     plotOptions: {
      //       bar: {
      //         horizontal: true,
      //         dataLabels: {
      //           position: "top"
      //         }
      //       }
      //     },
      //     dataLabels: {
      //       enabled: true,
      //       offsetX: -6,
      //       style: {
      //         fontSize: "12px",
      //         colors: ["#fff"]
      //       }
      //     },
      //     stroke: {
      //       show: true,
      //       width: 1,
      //       colors: ["#fff"]
      //     },
      //     xaxis: {
      //       categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007]
      //     }
      //   }
      // };

      // // var barChart = new ApexCharts(
      // //   document.querySelector("#barChart"),
      // //   barChartOptions
      // // );
      // // barChart.render();

      // // Bar Chart
      // const areaChartOptions = {
      //   chart: {
      //     type: "area"
      //   },
      //   series: [
      //     {
      //       name: "Cases",
      //       data: confirmed //[31, 40, 28, 51, 42, 109, 100]
      //     },
      //     {
      //       name: "Deaths",
      //       data: deaths //[11, 32, 45, 32, 34, 52, 41]
      //     }
      //   ],
      //   options: {
      //     dataLabels: {
      //       enabled: false
      //     },
      //     stroke: {
      //       curve: "smooth"
      //     },
      //     xaxis: {
      //       type: "datetime"
      //     },
      //     tooltip: {
      //       x: {
      //         format: "dd/MM/yy"
      //       }
      //     }
      //   }
      // };

      // const areaChart = new ApexCharts(
      //   document.querySelector("#areaChart"),
      //   areaChartOptions
      // );
      // areaChart.render();
    }

    loadData();
  }, []);

  return data ? (
    <PrimaryLayout>
      {/* <div className="row">
        <div className="col-md-12 col-lg-6">
          <div id="barChart" />
        </div>
        <div className="col-md-12 col-lg-12">
          <div id="areaChart" />
        </div>
      </div> */}
      <div className="row mt-4 mb-5">
        <div className="col-md-12 col-lg-12">
          <CountryChart data={data} />
        </div>
      </div>
      <div className="mt-4" style={{ marginBottom: "150px" }}>
        {/* <div className="col-md-12 col-lg-12">
          <CountryBarChart data={data} />
        </div> */}
        <TopTenChart data={data} />
      </div>
    </PrimaryLayout>
  ) : (
    <Loader />
  );
};

export default Charts;
