import React from "react";

interface StatisticsProps {
  title: string;
  count: Number;
  varient: string;
}

const Statistics: React.FC<StatisticsProps> = ({ title, count, varient }) => {
  function numberWithCommas(x: Number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <section className="statistics">
      <span className="title">{title}</span>
      <span className="count">{numberWithCommas(count)}</span>
      <span className={varient} />
    </section>
  );
};

export default Statistics;
