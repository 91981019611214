import { Affix, Button, Popover } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GlobalTable from "../components/GlobalTable";
import Statistics from "../components/Statistics";
import PrimaryLayout from "../layout/Primary";
import { getTimeline, TIMELINE_RESPONSE } from "../services/timelines";
import Loader from "../components/Loader";

const Home: React.FC = () => {
  const [timeline, setTimeline] = useState<TIMELINE_RESPONSE>();

  useEffect(() => {
    async function fetchData() {
      let res = await getTimeline();
      console.log("Timeline", res);
      res.locations = res.locations.sort((a: any, b: any) => {
        if (a.country < b.country) {
          return -1;
        }
        if (b.country < a.country) {
          return 1;
        }
        return 0;
      });
      setTimeline(res);
    }

    fetchData();
  }, []);

  return timeline ? (
    <PrimaryLayout>
      {timeline?.locations && timeline?.locations.length > 0 && (
        <div className="text-center" style={{ fontSize: "16px" }}>
          Data last updated{" "}
          <b>{moment(timeline.locations[0].last_updated).fromNow()}</b>
        </div>
      )}
      <div className="text-center">
        <Popover
          placement="bottom"
          content={
            <div>
              <a href="https://www.who.int/" target="_blank">
                World Health Organization (WHO)
              </a>
              <br />
              <a href="https://www.cdc.gov/" target="_blank">
                Centers for Disease Control and Prevention (CDC)
              </a>
              <br />
              <a href="https://bnonews.com" target="_blank">
                BNO News
              </a>
            </div>
          }
          trigger="click"
        >
          <span className="btn btn-link">(List of data sources)</span>
        </Popover>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 col-lg-4 offset-lg-2">
          <Statistics
            title="Total Confirmed Cases Worldwide"
            count={timeline?.latest?.confirmed || 0}
            varient="cases"
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <Statistics
            title="Total Deaths Worldwide"
            count={timeline?.latest?.deaths || 0}
            varient="deaths"
          />
        </div>
        {/* <div className="col-md-4 col-lg-4">
          <Statistics
            title="Recovered"
            count={timeline?.latest?.recovered || 0}
            varient="recovered"
          />
        </div> */}
      </div>
      <div className="row mt-3 mb-5">
        <div className="col-md-12 col-lg-12">
          <GlobalTable timeline={timeline?.locations || []} />
        </div>
      </div>
    </PrimaryLayout>
  ) : (
    <Loader />
  );
};

export default Home;
